var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemTable',{attrs:{"title":_vm.$tc('shop.article', 100),"items":_vm.articles,"headers":_vm.tableHeaders,"editable":_vm.editable,"defaultCRUDItem":_vm.defaultArticleItem,"crudPermissionSubject":"Article","crudURLPrefix":"/admin/article"},on:{"crudDialogSave":function (updatedItems) { return (_vm.articles = updatedItems); }},scopedSlots:_vm._u([{key:"crud-form",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":_vm.$t('shop.article.number'),"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", $$v)},expression:"item.number"}}),_c('v-select',{attrs:{"label":_vm.$tc('shop.article.group', 100),"items":_vm.articleGroups,"item-text":"names[0].name","item-value":function (group) { return group; },"value-comparator":function (a, b) {
                    if (Array.isArray(a) || Array.isArray(b)) { return false; }
                    return a.id === b.id;
                },"multiple":"","rules":[function (v) { return !!v; }],"required":""},model:{value:(item.groups),callback:function ($$v) {_vm.$set(item, "groups", $$v)},expression:"item.groups"}}),_c('v-select',{attrs:{"label":_vm.$tc('shop.article.producer'),"items":_vm.articleProducers,"item-text":"name","item-value":function (producer) { return producer; },"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.producer),callback:function ($$v) {_vm.$set(item, "producer", $$v)},expression:"item.producer"}}),_c('AdminLangCodeDescriptionTable',{attrs:{"editable":"","langCodeDescriptions":item.descriptions},on:{"crudDialogSave":function (updatedDescriptions) { return (item.descriptions = updatedDescriptions); }}}),_c('AdminShopArticlePropertyTable',{attrs:{"editable":"","articleProperties":item.properties},on:{"crudDialogSave":function (updatedProperties) { return (item.properties = updatedProperties); }}}),_c('AdminShopArticlePriceTable',{attrs:{"editable":"","articlePrices":item.prices},on:{"crudDialogSave":function (updatedPrices) { return (item.prices = updatedPrices); }}}),_c('AdminImageTable',{attrs:{"editable":"","images":item.images},on:{"crudDialogSave":function (updatedImages) { return (item.images = updatedImages); }}}),_c('v-text-field',{attrs:{"label":_vm.$t('shop.article.delivery'),"required":"","rules":[
                function (v) { return typeof v === 'number'; },
                function (v) { return v >= 0 ||
                    _vm.$t('form.rules.minValue', { minValue: 0 }).toString(); } ]},model:{value:(item.deliveryTime),callback:function ($$v) {_vm.$set(item, "deliveryTime", _vm._n($$v))},expression:"item.deliveryTime"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('shop.article.stock') + '?'},model:{value:(item.isStock),callback:function ($$v) {_vm.$set(item, "isStock", $$v)},expression:"item.isStock"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(item.isStock),expression:"item.isStock"}],attrs:{"label":_vm.$t('shop.article.stock.quantity')},model:{value:(item.stock),callback:function ($$v) {_vm.$set(item, "stock", _vm._n($$v))},expression:"item.stock"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }