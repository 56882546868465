var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemTable',{attrs:{"title":_vm.$tc('shop.article.property', 100),"items":_vm.articleProperties.map(function (item, key) { return (Object.assign({}, item, {key: key})); }),"item-key":"key","headers":_vm.tableHeaders,"editable":_vm.editable,"defaultCRUDItem":_vm.defaultArticlePropertyItem},on:{"crudDialogOpen":_vm.crudDialogOpened,"crudDialogSave":_vm.crudDialogSaved},scopedSlots:_vm._u([{key:"crud-form",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"label":_vm.$tc('shop.article.property'),"items":_vm.properties,"item-text":"names[0].name","item-value":function (prop) { return prop; },"rules":[function (v) { return !!v; }],"required":""},model:{value:(_vm.selectedProperty),callback:function ($$v) {_vm.selectedProperty=$$v},expression:"selectedProperty"}}),(_vm.selectedProperty.id !== -1)?_c('v-select',{attrs:{"label":_vm.$tc('shop.article.property.unit'),"items":_vm.selectedProperty.units,"item-text":"names[0].name","item-value":function (unit) { return unit; },"rules":[function (v) { return !!v; }],"required":""},on:{"change":function (unit) {
                    item.idAdditive = unit.idAdditive;
                }},model:{value:(item.unit),callback:function ($$v) {_vm.$set(item, "unit", $$v)},expression:"item.unit"}}):_vm._e(),(_vm.selectedProperty.id !== -1 && _vm.selectedProperty.needsValue)?[_c('v-text-field',{attrs:{"label":_vm.$t('shop.article.property.value'),"type":"number","rules":[function (v) { return !!v; }],"required":""},model:{value:(item.value.value),callback:function ($$v) {_vm.$set(item.value, "value", _vm._n($$v))},expression:"item.value.value"}}),_c('v-switch',{attrs:{"label":((_vm.$t('shop.article.property.value.min')) + "?: " + (_vm.needsMinValue ? _vm.$t('label.yes') : _vm.$t('label.no')))},on:{"change":function (value) {
                        item.value.minValue = value ? 0 : null;
                    }},model:{value:(_vm.needsMinValue),callback:function ($$v) {_vm.needsMinValue=$$v},expression:"needsMinValue"}}),(_vm.needsMinValue)?_c('v-text-field',{attrs:{"label":_vm.$t('shop.article.property.value.min'),"type":"number","rules":[function (v) { return !!v; }],"required":""},model:{value:(item.value.minValue),callback:function ($$v) {_vm.$set(item.value, "minValue", _vm._n($$v))},expression:"item.value.minValue"}}):_vm._e(),_c('v-switch',{attrs:{"label":((_vm.$t('shop.article.property.value.max')) + "?: " + (_vm.needsMaxValue ? _vm.$t('label.yes') : _vm.$t('label.no')))},on:{"change":function (value) {
                        item.value.maxValue = value ? 0 : null;
                    }},model:{value:(_vm.needsMaxValue),callback:function ($$v) {_vm.needsMaxValue=$$v},expression:"needsMaxValue"}}),(_vm.needsMaxValue)?_c('v-text-field',{attrs:{"label":_vm.$t('shop.article.property.value.max'),"type":"number","rules":[function (v) { return !!v; }],"required":""},model:{value:(item.value.maxValue),callback:function ($$v) {_vm.$set(item.value, "maxValue", _vm._n($$v))},expression:"item.value.maxValue"}}):_vm._e()]:_vm._e(),_c('v-text-field',{attrs:{"label":_vm.$t('shop.article.idAdditive')},model:{value:(item.idAdditive),callback:function ($$v) {_vm.$set(item, "idAdditive", $$v)},expression:"item.idAdditive"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }