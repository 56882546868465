









































































































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { Article, ArticleGroup, ArticleProducer } from "@/types/shop/article";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";
import AdminLangCodeDescriptionTable from "@/components/admin/language/descripton/AdminLangCodeDescriptionTable.vue";
import AdminShopArticlePropertyTable from "@/components/admin/shop/article/property/AdminShopArticlePropertyTable.vue";
import AdminShopArticlePriceTable from "@/components/admin/shop/article/price/AdminShopArticlePriceTable.vue";
import AdminImageTable from "@/components/admin/image/AdminImageTable.vue";

/**
 * AdminShopArticleTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
        AdminLangCodeDescriptionTable,
        AdminShopArticlePropertyTable,
        AdminShopArticlePriceTable,
        AdminImageTable,
    },
})
export default class AdminShopArticleTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private articles: Article[] = [];
    private articleGroups: ArticleGroup[] = [];
    private articleProducers: ArticleProducer[] = [];

    private defaultArticleItem: Article = {
        id: -1,
        number: "",
        sellCount: 0,
        createDate: new Date().toLocaleString("de"),
        groups: [],
        producer: {
            id: -1,
            name: "",
            homepage: "",
            description: "",
            image: {
                id: -1,
                src: "",
                alt: "",
            },
        },
        descriptions: [],
        images: [],
        isStock: false,
        stock: 0,
        deliveryTime: 0,
        reviews: [],
        prices: [],
        properties: [],
        deals: [],
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$t("shop.article.number").toString(),
                value: "number",
            },
            {
                text: this.$t("language.title").toString(),
                value: "descriptions[0].title",
            },
            {
                text: this.$tc("shop.article.producer").toString(),
                value: "producer.name",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            [this.articles, this.articleGroups, this.articleProducers] =
                await Promise.all([
                    this.fetchArticles(),
                    this.fetchArticleGroups(),
                    this.fetchArticleProducers(),
                ]);
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all articles
     *
     * @returns Promise<Article[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticles(): Promise<Article[]> {
        const response = (await axios.get<APIResponse<Article[]>>("/articles"))
            .data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all article groups
     *
     * @returns Promise<ArticleGroup[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticleGroups(): Promise<ArticleGroup[]> {
        const response = (
            await axios.get<APIResponse<ArticleGroup[]>>("/articles/groups")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all article producers
     *
     * @returns Promise<ArticleProducer[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticleProducers(): Promise<ArticleProducer[]> {
        const response = (
            await axios.get<APIResponse<ArticleProducer[]>>(
                "/articles/producers"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
