






import { Component, Vue } from "vue-property-decorator";
import AdminShopArticleTable from "@/components/admin/shop/article/AdminShopArticleTable.vue";

/**
 * AdminShopArticleManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminShopArticleTable,
    },
})
export default class AdminShopArticleManagement extends Vue {}
