








































import { Component, Prop, Vue } from "vue-property-decorator";
import { Image } from "@/types/image";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";

/**
 * AdminImageTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
    },
})
export default class AdminImageTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;
    @Prop({ type: Array, required: true })
    readonly images!: Image[];

    private defaultImageItem: Image = {
        id: -1,
        src: "",
        alt: "",
        fileObject: undefined,
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$t("image.alt").toString(),
                value: "alt",
            },
        ];
    }
}
